import {API, API_TIKTOK, API_WithSwal} from '../../utility/API'
import {API_WithSwal as Artist_API_WithSwal} from '@modules/artist/utility/API'
import {_setAPIToken} from '../../utility/Utils'
import _ from "lodash"
import { saveAs } from 'file-saver'
import axios from 'axios'


//***************** Curators Vs Campaigns *******************//

export const _addCuratorVsCampaing = (data, callback, callbackErr) => {
    API.post(`curator/curators_vs_campaigns`, data)
        .then(function ({data}) {
            callback(data)
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}

//************************************//
export const _storeCuratorVsCampaignPlaylist = (data, callback, callbackErr) => {
    API.put(`curator/curator_vs_campaign/playlist/${data.id}`, data)
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}

//************************************//
export const _submitCuratorVsCampaignPlaylist = (id, status, callback, callbackErr) => {
    API_WithSwal.put(`curator/curator_vs_campaign/playlist/submit/${id}/${status}`)
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}

//************************************//
export const _getCampaignInfo = (id, callback, callbackErr) => {
API.get(`campaign/${id}`)
    .then(function ({data}) {
        callback(data)
    })
    .catch(function ({data}) {
    })
}
//************************************//
export const _getArtistCustomCampaigns = (data, callback, callbackErr) => {
  API.get(`artist/campaigns`, {
      params: data
  })
      .then(function ({data}) {
          callback(data)
      })
      .catch(function (error) {
        // callbackErr(data?.data?.errors)
        const data = error?.response?.data
          if (data) {
            const { data: responseData } = data
            callbackErr(responseData?.errors)
          } else {
            console.log('Error:', error)
          }
      })
  }

  export const _getCuratorCustomCampaigns = (data, callback, callbackErr) => {
    API.get(`curator/campaigns`, {
        params: data
    })
        .then(function ({data}) {
            callback(data)
        })
        .catch(function (error) {
          // callbackErr(data?.data?.errors)
          const data = error?.response?.data
            if (data) {
              const { data: responseData } = data
              callbackErr(responseData?.errors)
            } else {
              console.log('Error:', error)
            }
        })
    }
//************************************//
export const _getCampaignInfoforView = (id, callback, callbackErr) => {
    API.get(`campaign/getforview/${id}`)
        .then(function ({data}) {
            callback(data)
        })
        .catch(function ({data}) {
        })
}

//************************************//
export const _getCampaignByCuratorVsCampaignId = (id, callback, callbackErr) => {
API.get(`curator/campaign/${id}`)
    .then(function ({data}) {
        callback(data)
    })
    .catch(function ({data}) {
    })
}

//************************************//
export const _getPublishedCampaigns = (data, callback, callbackErr) => {
API.get(`published/campaigns`, {
    params: data
})
    .then(function ({data}) {
        callback(data)
    })
    .catch(function ({data}) {
    })
}


//***************** Users *******************//

export const _resendVerificationCode = () => {
    API_WithSwal.post(`user/resend-code`)
        .then(function ({data}) {
        })
        .catch(function ({data}) {
        })
}

export const _getUserInfo = (id, callback) => {
    API.get(`user/get-info/${id}`)
        .then(function ({data}) {
            callback(data)
        })
        .catch(function ({data}) {
        })
}

export const _updateUser = (id, data, callback) => {
    API_WithSwal.post(`user/update-user/${id}`, data)
        .then(function ({data}) {
            callback(data)
        })
        .catch(function ({data}) {
        })
}

//***************** Payouts *******************//

//************************************//
export const _getPayoutInfo = (id, callback) => {
    API.get(`curator/payouts/${id}`)
        .then(function ({data}) {
            callback(data)
        })
        .catch(function ({data}) {
        })
}
//************************************//
export const _payPayout = (data, callback, callbackErr) => {
    const formData = new FormData()
    _.each(data, (x, key) => {
        if (key === 'attachment_link') {
            if (!_.isString(x)) {
                formData.append(key, x[0])
            }
        } else {
            formData.append(key, x)
        }
    })
    API_WithSwal.post(`curator/payout/${data.id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" }
    })
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}

//***************** Invoices *******************//

//************************************//
export const _getInvoiceInfo = (id, callback) => {
    API.get(`artist/invoices/${id}`)
        .then(function ({data}) {
            callback(data)
        })
        .catch(function ({data}) {
        })
}
//************************************//
export const _payInvoice = (data, callback, callbackErr) => {
  API_WithSwal.post(`artist/invoice/${data.id}`, data)
    .then(function () {
      callback()
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}
//************************************//
export const _login = ({email, password}, callback, callbackErr) => {
    API.post('user/login', {email, password})
        .then(function (res) {
            callback(res)
        })
        .catch(function (res) {
            callbackErr(res)
        })
}

//************************************//
export const _autoLogin = (dispatch, ability, callback) => {
  const storageUserToken = JSON.parse(localStorage.getItem('AUTH_TOKEN'))
  const sessionUserToken = JSON.parse(sessionStorage.getItem('AUTH_TOKEN'))
  let user = (sessionUserToken || false)
  if (user && user.token && user.user) {
    _setAPIToken(user.token)
    // dispatch({type:"USER_LOGIN", userData:user.user, token:user.token})
    // if (user.user.abilities) {
    //   ability.update(user.user.abilities)
    // }
    API.get('user/info', {email: user.email, token: user.token})
      .then(function (res) {
        if (res.data.token) {
          const {data} = res
          sessionStorage.setItem("AUTH_TOKEN", JSON.stringify({email: user.email, token: data.token, user: data.user}))
          _setAPIToken(data.token)
          dispatch({type: "USER_LOGIN", userData: data.user, token: data.token})
          if (data.user.abilities) {
            ability.update(data.user.abilities)
          }
        }
        callback()
      })
      .catch(function (res) {
        localStorage.removeItem('AUTH_TOKEN')
        sessionStorage.removeItem('AUTH_TOKEN')
        callback()
      })
  } else if (user = (storageUserToken || false)) {
    if (user && user.token && user.email) {
      _setAPIToken(user.token)
      API.get('user/info', {email: user.email, token: user.token})
        .then(function (res) {
          if (res.data.token) {
            const {data} = res
            sessionStorage.setItem("AUTH_TOKEN", JSON.stringify({email: user.email, token: data.token, user: data.user}))
            _setAPIToken(data.token)
            dispatch({type: "USER_LOGIN", userData: data.user, token: data.token})
            if (data.user.abilities) {
              ability.update(data.user.abilities)
            }
          }
          callback()
        })
        .catch(function (res) {
          localStorage.removeItem('AUTH_TOKEN')
          sessionStorage.removeItem('AUTH_TOKEN')
          callback()
        })
    }
  } else {
    callback()
  }
}


//***************** stripe checkout *******************//
//create checkout
export const _stripeCreateCheckout = (data, callback, callbackErr) => {
  API.post(`stripe/create/checkout`, data)
    .then(function ({data}) {
      callback(data)
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}

export const _stripePaymentSuccess = (data, callback, callbackErr) => {
  API.post(`stripe/checkout/check/pay`, data)
    .then(function ({data}) {
      callback(data)
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}
//***************** Admins *******************//
//************************************//
export const _editAdminInfo = (data, callback, callbackErr) => {
  API_WithSwal.put(`admins/${data.id}`, data)
    .then(function ({data}) {
      callback(data)
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}
//************************************//
export const _deleteAdmin = (id, callback) => {
  API.delete(`admins/${id}`)
    .then(function ({data}) {
      callback(data)
    })
    .catch(function ({data}) {
    })
}
export const _deleteUser = (id, callback) => {
  API.delete(`user/delete/${id}`)
      .then(function ({data}) {
          callback(data)
      })
      .catch(function ({data}) {
      })
}

//***************** Scraping Jobs *******************//

export const _addScrapingJob = (data, callback, callbackErr) => {
  API_WithSwal.post(`scrapingjobs`, data)
    .then(function ({data}) {
      callback(data)
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}
//************************************//
export const _editScrapingJob = (data, callback, callbackErr) => {
  API_WithSwal.put(`scrapingjobs/${data.id}`, data)
    .then(function ({data}) {
      callback(data)
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}
//************************************//
export const _deleteScrapingJob = (id, callback) => {
  API.delete(`scrapingjobs/${id}`)
    .then(function ({data}) {
      callback(data)
    })
    .catch(function ({data}) {
    })
}
//************************************//
export const _startScrapingJob = (id, callback) => {
  API.delete(`scrapingjob/start-scraping/${id}`)
    .then(function ({data}) {
      callback(data)
    })
    .catch(function ({data}) {
    })
}

//***************** Notifications *******************//

export const _sendPushNotification = (data, callback, callbackErr) => {
  API_WithSwal.post(`notifications`, data)
    .then(function ({data}) {
      callback(data)
    })
    .catch(function (data) {
      callbackErr(data?.data?.errors)
    })
}
//************************************//
export const _getAllAdminsWithQ = async (q = '') => {
  const {data} =  await API.get('admin/getAllAdminsWithQ', {
    params: {
      limit: 20,
      q
    }
  })
  return _.map(data.municipalities, (v, k) => {
    return {value: v.id, label: v.name}
  })
}
//************************************//
export const _getAllGenresWithQ = async (q = '') => {
    const {data} =  await API.get('user/genre/getAllGenresWithQ', {
        params: {
            limit: 20,
            q
        }
    })
    return _.map(data.genres, (v, k) => {
        return {value: v.id, label: v.name}
    })
}

//************************************//
export const _getAllInterestsWithQ = (q = '', type, callback) => {
    API.get('interest/getAllInterestsWithQ', {
        params: {
            limit: 20,
            q,
            type
        }
    }).then(function ({data}) {
        callback(data)
    })
}
//************************************//
//************************************//
//************************************//
//************************************//
//************************************//
//************************************//
//************************************//
//************************************//
//************************************//
//************************************//
//************************************//
//************************************//
export const _loginTwoFactor = ({email, code}, callback, callbackErr) => {
  API.post('login-2fa', {email, code})
    .then(function (res) {
      callback(res)
    })
    .catch(function ({data}) {
      callbackErr(data)
    })
}
//************************************//
export const _register = (data, callback, callbackErr) => {
    API.post('user/store-user', data)
        .then(function ({data}) {
            callback(data)
        })
        .catch(function ({data}) {
            callbackErr(data.data)
        })
}
//************************************//
export const _forgetPassword = ({email}, callback, callbackErr) => {
    API.post('forget-password', {email})
        .then(function ({data}) {
            callback(data)
        })
        .catch(function ({data}) {
            callbackErr(data.data)
        })
}
//************************************//
export const _resetPassword = (data, callback, callbackErr) => {
    API.post('user/reset-password', data)
        .then(function (res) {
            callback(res)
        })
        .catch(function ({data}) {
            callbackErr(data?.data)
        })
}
//************************************//
export const _changePassword = (data, callback, callbackErr) => {
  API.post('user/account/change-password', {...data})
    .then(function ({data}) {
      callback(data)
    })
    .catch(function ({data}) {
      callbackErr(data.data)
    })
}
//************************************//
export const _logout = () => {
    return dispatch => {
    //     API.post('logout')
    //         .then(function () {
                // ** Remove user, accessToken & refreshToken from localStorage
                localStorage.removeItem('sessionId')
                localStorage.removeItem('AUTH_TOKEN')
                sessionStorage.removeItem('AUTH_TOKEN')
                _setAPIToken('')
                dispatch({ type: 'USER_LOGOUT' })
            //     location.reload()
            // })
            // .catch(function ({data}) {
            // })
    }
}
//************************************//
export const _getMyProfile = (dispatch) => {
    API.get('user/account/my-profile')
        .then(function ({data}) {
            const userToken = JSON.parse(sessionStorage.getItem('AUTH_TOKEN'))
            userToken.user = {...userToken.user, ...data.basic_info}
            sessionStorage.setItem("AUTH_TOKEN", JSON.stringify(userToken))
            dispatch({type: 'USER_MY_PROFILE', data})
        })
        .catch(function (res) {})
}
//************************************//
export const _changeBasicInfo = (data, callback, callbackErr) => {
    return dispatch => {
        API.post('user/account/change-basic-info', data)
          .then(function ({data}) {
              _getMyProfile(dispatch)
          })
          .catch(function ({data}) {
              callbackErr(data.data)
          })
    }

}
//************************************//
export const _changeGeneralInfo = (data, callback, callbackErr) => {
    return dispatch => {
        API.post('user/account/change-general-info', {...data})
          .then(function ({data}) {
              _getMyProfile(dispatch)
          })
          .catch(function ({data}) {
              callbackErr(data.data)
          })
    }

}
//************************************//
export const _saveSupportEmail = (data, callback, callbackErr) => {
    API.post('support/store', {...data})
      .then(function ({data}) {
      })
      .catch(function ({data}) {
          callbackErr(data.data)
      })

}
//************************************//
export const _getAllGenresWithQForFilter = (q = '', callback) => {
  if (q.length < 3) {
    // If the user has not typed at least 3 letters, return an empty array
    return []
  }
    API.get('user/genre/getAllGenresWithQ', {
        params: {
            limit: 20,
            q
        }
    }).then(function({data}) {
        callback(data.genres)
    })
}

//************************************//
export const _getAllTiersWithQForFilter =  (q = '', type, callback) => {
    API.get('tier/getAllTiersWithQ', {
        params: {
            limit: 100,
            q,
            type
        }
    }).then(({data}) => {
        callback(data.tiers)
    })
}

// **********************************************
// ******************FROMS APIS******************
// **********************************************
export const _addForm = (data, callback, callbackErr) => {
  const formData = new FormData()
  _.each(data, (x, key) => {
      if (key === 'media_link' && !_.isString(x)) {
          if (!_.isEmpty(x)) {
              formData.append(key, x[0])
          }
      } else {
          if (x) {
              formData.append(key, x)
          }
      }
  })
  API_WithSwal.post(`user/new_form`, formData, {
    headers: { "Content-Type": "multipart/form-data" }
})
  .then(function (data) {
      callback(data)
  })
  .catch(function (data) {
      callbackErr(data?.data?.errors)
  })
}

export const _getFormInfo = (id, callback) => {
  API.get(`get_form/${id}`)
      .then(function (data) {
          callback(data)
      })
      .catch(function (data) {
      })
}

export const _getUserInfoSpotify = async (callback) => {
  await API.get(`user/info/spotify`)
      .then(function (data) {
          callback(data)
      })
      .catch(function (data) {
      })
}
//***************** Express Playlist View ******************* *//

export const _editExpressPlayList = (data, callback, callbackErr) => {
  API_WithSwal.put(`express/curator/playlist/edit/${data.id}`, data)
      .then(function () {
          callback()
      })
      .catch(function (data) {
          callbackErr(data?.data?.errors)
      })
}

export const _getExpressCuratorPlaylistDetails = (id, callback) => {
  API.get(`express/curator/shows/playlist/${id}`)
      .then(function ({data}) {
          callback(data)
      })
      .catch(function ({data}) {
      })
}

export const _getPerformanceChartStats = (id, callback) => {
  API.get(`express/curator/campaigns/performance/${id}`)
      .then(function ({data}) {
          callback(data.playlist)
      })
      .catch(function ({data}) {
      })
}
export const _approveExpressPlaylistCampaign = (data, callback, callbackErr) => {
  console.log(`approve campaign ${JSON.stringify(data)}`)
  API_WithSwal.post(`express/curator/approve/campaign`, data)
      .then(function () {
          callback()
      }).catch(function (data) {
        callbackErr(data?.data)
    })
}
/******************** Campaigns *******************/
//get campaign reasons
export const _getAllReasonsWithQ = async (q = '') => {
  const {data} =  await API.get('express/curator/campaign/reasons?', {
      params: {
          limit: 20,
          q
      }
  })
  return _.map(data.reasons, (v, k) => {
      return {value: v.id, label: v.reason}
  })

}
//campaign decline
export const _declineExpressCampaign = (data, callback, callbackErr) => {
  API_WithSwal.post(`express/curator/decline/campaign`, data)
      .then(function () {
          callback()
      })
      .catch(function (data) {
          callbackErr(data?.data?.errors)
      })
}
export const _getExpressCampaignInfo = (id, callback) => {
  API.get(`/express/artist/show/campaign/${id}`)
      .then(function ({data}) {
          callback(data)
      })
      .catch(function ({data}) {
      })
}
export const _storeCuratorExpressPlaylist = (data, callback, callbackErr) => {
  API_WithSwal.post(`express/curator/playlist/create`, data)
      .then(function () {
          callback()
      })
      .catch(function (data) {
          callbackErr(data?.data?.errors)
      })
}
//************************************//
export const _getExpressPlaylistCurator = (data, callback, callbackErr) => {
  API.get(`express/curator/playlists`, {
      params: data
  })
      .then(function ({data}) {
          callback(data)
      })
      .catch(function (error) {
        // callbackErr(data?.data?.errors)
        const data = error?.response?.data
          if (data) {
            const { data: responseData } = data
            callbackErr(responseData?.errors)
          } else {
            console.log('Error:', error)
          }
      })
  }
export const _getExpressPlaylistCampaigns = (data, callback, callbackErr) => {
  API.get(`express/curator/shows/campaigns/${data.id}`, {
      params: data
  })
      .then(function ({data}) {
          callback(data)
      })
      .catch(function (error) {
        // callbackErr(data?.data?.errors)
        const data = error?.response?.data
          if (data) {
            const { data: responseData } = data
            callbackErr(responseData?.errors)
          } else {
            console.log('Error:', error)
          }
      })
  }
/******************** spotify  spotify/user/playlists? *********************/
export const _getCurrentCuratorSpotifyPlaylists = (user, callback) => {
  API.get(`spotify/user/playlists?user=${user}`)
      .then(function ({data}) {
          callback(data)
      })
      .catch(function ({data}) {
      })
}

export const _getLoginSpotifyUrl = (callback) => {
  API.get(`spotify/login`)
      .then(function ({data}) {
          callback(data)
      })
      .catch(function ({data}) {
      })
}
//callback  
export const _getSpotifyCallBack = (code, callback) => {
  API.get(`spotify/callback?code=${code}`)
      .then(function ({data}) {
          callback(data)
      })
      .catch(function ({data}) {
      })
}

/***************   Tiers and Durations and Genres  **************/
export const _getAllExpressPlaylistsTiers = (callback) => {
  API.get('express/curator/all-tiers')
      .then(function ({data}) {
          console.log(`durations ${data.tiers}`)
          callback(data.tiers)
      })
      .catch(function ({data}) {
      })
}
export const _getAllExpressPlaylistsDurations = (callback) => {
  API.get('express/curator/all-durations')
      .then(function ({data}) {
          console.log(`durations ${data.durations}`)
          callback(data.durations)
      })
      .catch(function ({data}) {
      })
}
export const _getAllExpressPlaylistsGenresWithQ = async (query = '') => {
  if (query.length < 3) {
      // If the user has not typed at least 3 letters, return an empty array
      const {data} =  await API.get('campaigns/genre/getAllGenresWithQ', {
        params: {
            limit: 100,
            query,
            page: 1
        }
    })
    return _.map(data.genres, (v, k) => {
        return {value: v.id, label: v.name}
    })
    }
      const {data} =  await API.get('express/curator/playlist/genres', {
          params: {
              limit: 20,
              query,
              page: 1
          }
      })
      return _.map(data.data, (v, k) => {
          return {value: v.id, label: v.name}
      })
}
//

export const _getAllCustomCampaignsGenresWithQ = async (query = '') => {
  if (query.length < 3) {
      // If the user has not typed at least 3 letters, return an empty array /
      const {data} =  await API.get('campaigns/genre/getAllGenresWithQ', {
        params: {
            limit: 100,
            query,
            page: 1
        }
    })
    return _.map(data.genres, (v, k) => {
        return {value: v.id, label: v.name}
    })
    }
      const {data} =  await API.get('express/curator/playlist/genres', {
          params: {
              limit: 100,
              query,
              page: 1
          }
      })
      return _.map(data.data, (v, k) => {
          return {value: v.id, label: v.name}
      })
}
/*************************** Curator Express Campaigns  ****************************/
export const _getExpressCuratorCampaigns = (data, callback, callbackErr) => {
  API.get(`express/curator/playlist/campaigns`, {
      params: data
  })
      .then(function ({data}) {
          callback(data)
      })
      .catch(function (error) {
        // callbackErr(data?.data?.errors)
        const data = error?.response?.data
          if (data) {
            const { data: responseData } = data
            callbackErr(responseData?.errors)
          } else {
            console.log('Error:', error)
          }
      })
  }

/*************************** Artist Express Campaigns  ****************************/

export const _getExpressArtistCampaigns = (data, callback, callbackErr) => {
  API.get(`express/artist/campaigns`, {
      params: data
  })
      .then(function ({data}) {
          callback(data)
      })
      .catch(function (error) {
        // callbackErr(data?.data?.errors)
        const data = error?.response?.data
          if (data) {
            const { data: responseData } = data
            callbackErr(responseData?.errors)
          } else {
            console.log('Error:', error)
          }
      })
  }

  //get min tier price
  export const _getExpressArtistMinTier = (callback, callbackErr) => {
    API.get(`express/artist/min/tier`)
        .then(function ({data}) {
            callback(data)
        })
        .catch(function (error) {
          // callbackErr(data?.data?.errors)
          const data = error?.response?.data
            if (data) {
              const { data: responseData } = data
              callbackErr(responseData?.errors)
            } else {
              console.log('Error:', error)
            }
        })
    }

    export const _checkUriUrl = (link, callback) => {
      const valid = 'https://open.spotify.com/track/'
      if (_.startsWith(link, valid)) {
          const track_id = link.substr(valid.length)
          API.get(`spotify/get-track`, {
              params: {
                  track_id
              }
          })
            .then(({data}) => callback({code: 1, other: data?.track}))
      } else {
          callback({code:2})
      }
  } 
  
  export const _checkTrackIfIsExists = (data, callback, callbackErr) => {
    API.post(`express/track/exists`, {
     track_id: data.track_id,
     playlist_id: data.playlist_id
    })
    .then(({data}) => callback({data}))
}

export const _getExpressPlaylistArtistForApply = (data, callback, callbackErr) => {
  API.get(`express/artist/playlists?start=0&length=10&limit=10&page=1`, {
      params: data
  })
      .then(function ({data}) {
          callback(data)
      })
      .catch(function (error) {
        // callbackErr(data?.data?.errors)
        const data = error?.response?.data
          if (data) {
            const { data: responseData } = data
            callbackErr(responseData?.errors)
          } else {
            console.log('Error:', error)
          }
      })
  }

  export const _storeArtistExpressPlaylistCampaign = (data, callback, callbackErr) => {
    API_WithSwal.post(`express/artist/campaign/create`, data)
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
  }
  export const _stripePaymentCampaignSuccess = (data, callback, callbackErr) => {
    API.post(`stripe/campaign/checkout/check/pay`, data)
      .then(function ({data}) {
        callback(data)
      })
      .catch(function (data) {
        callbackErr(data?.data?.errors)
      })
  }
/********* Tiktok Released Campaign ***********/
export const _checkTiktokMusic = (link, callback) => {
  const valid = 'https://www.tiktok.com/music/'
  if (_.startsWith(link, valid)) {
      const music_id = link.substr(valid.length)
      API.get(`check-tiktok-music/${music_id}`)
        .then((data) => {
          console.log(data)
          callback({code: 1, data})
        })
  } else {
      callback({code:2})
  }
}
export const _checkTiktokVideo = (link, callback) => {
  const formData = new FormData()
  formData.append('video_link', link)

  API.post('check-tiktok-video', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then((response) => {
      console.log(`response ${response}`)
      if (response) {
        callback({ code: 1, data: response })
      } else {
        callback({ code: 2 })
      }
    })
    .catch((error) => {
      console.error(error)
      callback({ code: 2 })
    })
}
//***************** Languages *******************//

//************************************//
export const _getAllLanguagesWithQ = async (q = '') => {
  const {data} =  await API.get('language/getAllLanguagesWithQ', {
      params: {
          limit: 20,
          q
      }
  })
  return _.map(data.languages, (v, k) => {
      return {value: v.id, label: v.name}
  })
}
//************************************//
export const _getAllTiers = (q = '', type, callback) => {
  API.get('tier/getAllTiersWithQ', {
      params: {
          limit: 100,
          q,
          type
      }
  }).then(({data}) => {
      callback(data.tiers)
  })
}
//************************************//
export const _addTiktokPerformance = (data, callback, callbackErr) => {
      API.post(`campaign/tiktok/performance`, data)
          .then(function () {
              callback()
          })
          .catch(function (data) {
              callbackErr(data?.data?.errors)
          })
}
export const _addCampaign = (data, callback, callbackErr) => {
    const formData = new FormData()
    _.each(data, (x, key) => {
        if (key === 'media_link' && !_.isString(x)) {
            if (!_.isEmpty(x)) {
                formData.append(key, x[0])
            }
        } else {
            if (x) {
                formData.append(key, x)
            }
        }
    })

        Artist_API_WithSwal.post(`artist/campaigns`, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then(function () {
                callback()
            })
            .catch(function (data) {
                callbackErr(data?.data?.errors)
            })
}
//***************** Outgoing Payments & transfers *******************//
//get convert from usd to gbp
export const _getFromUsdToGbp = (amount, callback, callbackErr) => {
  API.get(`payments/wise/gbp/usd/${amount}`)
      .then(function ({data}) {
        console.log(data)
          callback(data)
      })
      .catch(function (error) {
        // callbackErr(data?.data?.errors)
        const data = error?.response?.data
          if (data) {
            const { data: responseData } = data
            callbackErr(responseData?.errors)
          } else {
            console.log('Error:', error)
          }
      })
  }
  //payouts last 30 days
  export const _getPayoutsLastMonthChartStats = (id, callback) => {
    API.get(`/curator/payouts/last/month/stats`)
        .then(function ({data}) {
            callback(data.payouts)
        })
        .catch(function ({data}) {
        })
  }
//get min tier price
export const _getPayoutsSum = (callback, callbackErr) => {
  API.get(`curator/sum/payouts`)
      .then(function ({data}) {
          callback(data)
      })
      .catch(function (error) {
        // callbackErr(data?.data?.errors)
        const data = error?.response?.data
          if (data) {
            const { data: responseData } = data
            callbackErr(responseData?.errors)
          } else {
            console.log('Error:', error)
          }
      })
  }
  //wise currencies search
  export const _getAllWiseCurrenciesWithQ = async (q = '') => {
    const {data} =  await API.get('payments/wise/currencies', {
        params: {
            limit: 30,
            q
        }
    })
    return _.map(data.currency, (v, k) => {
        return {value: v.id, label: v.currencyCode, target: v.isTargetCurrency}
    })
}
//outgoing payments with search
 //wise currencies
 export const _getAllOutGoingPaymentWithQ = async (q = '') => {
  const {data} =  await API.get('payments/outgoing/types/search', {
      params: {
          limit: 30,
          q
      }
  })
  return _.map(data.payment, (v, k) => {
      return {value: v.id, label: v.name}
  })
}

//store wise payment info
export const _createPaymentInfo = (data, callback) => {
  API_WithSwal.post(`payments/wise/transfer/info/store`, data)
      .then(function ({data}) {
          callback(data)
      })
      .catch(function ({data}) {
      })
}

//create quote and transfer request
// export const _createQuoteAndTransfer = async (id, callback) => {
//   //check user info if exists
//  await API.get(`payments/wise/user/info/check`)
//       .then(function ({data}) {
//           callback(data)
//       })
//       .catch(function ({data}) {
//       })
//   //check if user have unpaid payouts
//   await API.get(`payments/wise/unpaid/payouts/check`)
//       .then(function ({data}) {
//           callback(data)
//       })
//       .catch(function ({data}) {
//       })   
      
//      const account = await API.get(`payments/wise/create/account`)
//       .then(function ({data}) {
//           callback(data)
//       })
//       .catch(function ({data}) {
//       }) 
//       console.log(account.id)
// }

export const _createQuoteAndTransfer = async (data, callback) => {
  try {
    // Check user info if exists
    // const userInfo = await API.get(`payments/wise/user/info/check`)
    // Check if user has unpaid payouts
    const unpaidPayouts = await API.get(`payments/wise/unpaid/payouts/check`)
    // Get the sum
    const sum = await API.get(`curator/sum/payouts`)
    const sumValue = sum.data.sum

    console.log(sum.data.sum)

    // Create the account
    const account = await API.get(`payments/wise/create/account`)
    const accountID = account.data.id

    console.log(account.data.id)

    // Create the quote
    const quote = await API.get(`payments/wise/create/quote/payouts/${data.gbp}`)
    const quoteID = quote.data.id

    console.log(quote.data.id)

    // Create transfer for payout and user
    const createTransfer = await API_WithSwal.post(`payments/wise/create/transfer`, {
      wise_account_id: accountID,
      wise_transfer_id: '',
      wise_balance_transaction_id: '',
      wise_quote_id: quoteID,
      amount: sumValue,
      status_id: 1,
      email: data.email
    })

    return createTransfer.data
  } catch (error) {
    throw error
  }
}

//view transfer details
//************************************//
export const _getTransferInfo = (id, callback) => {
  API.get(`payments/wise/user/transfer/view/${id}`)
      .then(function ({data}) {
          callback(data)
      })
      .catch(function ({data}) {
      })
}
//npm install file-saver to install file saver
export const _getDownloadPDFWise = (id, callback) => {
  API.get(`payments/wise/user/pdf/${id}`, { responseType: 'arraybuffer' })
    .then(function (response) {
      const filename = 'receipt.pdf'
      const contentType = 'application/pdf'
      
      const blob = new Blob([response], { type: contentType })
      console.log(blob)
      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // For Internet Explorer
        window.navigator.msSaveBlob(blob, filename)
      } else {
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = filename
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(url)
      }
      callback(response)
    })
    .catch(function (error) {
      console.error('Error occurred while downloading PDF', error)
    })
}
// export const _getDownloadPDFWise = (id, callback) => {
//   API.get(`payments/wise/user/pdf/${id}`)
//     .then(function (response) {
//       const blob = new Blob([response.data], { type: 'application/pdf' })
//       const url = URL.createObjectURL(blob)
//       const a = document.createElement('a')
//       a.href = url
//       a.download = 'receipt.pdf'
//       a.click()
//       callback(response.data)
//     })
//     .catch(function (error) {
//       console.error('Error occurred while downloading PDF', error)
//     })
// }

export const _deleteWiseUserInfo = (id, callback) => {
  API_WithSwal.delete(`payments/wise/user/info/delete/${id}`)
    .then(function ({data}) {
      callback(data)
    })
    .catch(function ({data}) {
    })
}

/***************************** Social OAuth ******************************/
//get gmail url
export const _getOauthGmailUrl = (callback) => {
  API.get(`oauth/gmail/redirect`)
      .then(function ({data}) {
          callback(data)
      })
      .catch(function ({data}) {
      })
}
//get user gmail info and log or register
export const _getOauthGmailLog = (data, callback, callbackErr) => {
  API.get(`oauth/gmail/register/login?code=${data.code}`, {params: {
    auth_type: data.auth_type
  }})
      .then(function ({data}) {
          callback(data)
      })
      .catch(function (data) {
        callbackErr(data)
    })
}
//complete profile
export const _oauthCompleteRegister = (data, callback, callbackErr) => {
  API.post('oauth/gmail/register/complete', data)
      .then(function ({data}) {
          callback(data)
      })
      .catch(function ({data}) {
          callbackErr(data.data)
      })
}

/*************** Code Verification *********************/


//complete profile
export const _sendVerificationCodeTransfer = (data, callback, callbackErr) => {
  API.post('payments/send/verification/code', data)
      .then(function ({data}) {
          callback(data)
      })
      .catch(function ({data}) {
         
      })
}
//verify email
export const _verifyCodeTransfer = (data, callback, callbackErr) => {
  API.post('payments/verify/code', data)
      .then(function ({data}) {
          callback(data)
      })
      .catch(function ({data}) {
      })
}