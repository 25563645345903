import { API, API_WithSwal, API_TIKTOK, SPOTIFY_API } from "../../utility/API"
import _ from "lodash"
import axios from "axios"
import { API_WithSwal as Artist_API_WithSwal } from '@modules/artist/utility/API'

//***************** Genres *******************//

export const _addGenre = (data, callback, callbackErr) => {
    API_WithSwal.post(`genres`, data)
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
//************************************//
export const _editGenre = (data, callback, callbackErr) => {
    API_WithSwal.put(`genres/${data.id}`, data)
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
//************************************//
export const _deleteGenre = (id, callback) => {
    API.delete(`genres/${id}`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
//************************************//

export const _getAllGenresWithQ = async (q = '') => {
    if (process.env.REACT_APP_AUTH_MODULE === 'artist') {
        const { data } = await API.get('user/genre/getAllGenresWithQ', {
            params: {
                limit: 20,
                q
            }
        })
        return _.map(data.genres, (v, k) => {
            return { value: v.id, label: v.name }
        })
    } else {
        const { data } = await API.get('genre/getAllGenresWithQ', {
            params: {
                limit: 20,
                q
            }
        })
        return _.map(data.genres, (v, k) => {
            return { value: v.id, label: v.name }
        })
    }

}
//***************** Express Playlists *******************//
//************************************//
// export const _getAllExpressPlaylistsTiersWithQ = async (q = '') => {
//     const {data} =  await API.get('express/all-tiers', {
//         params: {
//             limit: 20,
//             q
//         }
//     })
//     return _.map(data.tiers, (v, k) => {
//         return {value: v.id, label: `Tier:${v.tier_name}, Price:$${v.tier_pricing}, Min Followers:${v.minimum_followers_threshold}`}
//     })
// }
//************************************//
// export const _getAllExpressPlaylistsDurationsWithQ = (q = '', callback) => {
//     API.get('express/all-durations', {
//         params: {
//             limit: 100,
//             q
//         }
//     }).then(({data}) => {
//         callback(data.durations)
//     })
// }
//Campaigns menu tab list stats
export const _getAdminExpressStat = (callback) => {
    API.get('express/campaigns/stat/admin')
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
export const _storeExpressPlaylist = (data, callback, callbackErr) => {
    API_WithSwal.post(`express/playlist/create`, data)
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
export const _getAllExpressPlaylistsTiers = (callback) => {
    API.get('express/all-tiers')
        .then(function ({ data }) {
            console.log(`durations ${data.tiers}`)
            callback(data.tiers)
        })
        .catch(function ({ data }) {
        })
}
export const _getAllExpressPlaylistsDurations = (callback) => {
    API.get('express/all-durations')
        .then(function ({ data }) {
            console.log(`durations ${data.durations}`)
            callback(data.durations)
        })
        .catch(function ({ data }) {
        })
}
export const _getAllExpressPlaylistsGenresWithQ = async (query = '') => {
    if (query.length < 3) {
        // If the user has not typed at least 3 letters, return an empty array
        return []
    }
    const { data } = await API.get('express/playlist/genres', {
        params: {
            limit: 100,
            query,
            page: 1
        }
    })
    return _.map(data.data, (v, k) => {
        return { value: v.id, label: v.name }
    })
}
export const _getAllReasonsWithQ = async (q = '') => {
    const { data } = await API.get('express/campaign/reasons?', {
        params: {
            limit: 20,
            q
        }
    })
    return _.map(data.reasons, (v, k) => {
        return { value: v.id, label: v.reason }
    })

}
export const _getAllReasonsSpotifyWithQ = async (q = '') => {
    const { data } = await API.get('campaign/spotify/reasons?', {
        params: {
            limit: 20,
            q
        }
    })
    return _.map(data.reasons, (v, k) => {
        return { value: v.id, label: v.reason }
    })

}
export const _getAllReasonsTiktokWithQ = async (q = '') => {
    const { data } = await API.get('campaign/tiktok/reasons?', {
        params: {
            limit: 20,
            q
        }
    })
    return _.map(data.reasons, (v, k) => {
        return { value: v.id, label: v.reason }
    })

}
export const _getAllReasonsCustomWithQ = async (q = '') => {
    const { data } = await API.get('campaign/custom/reasons?', {
        params: {
            limit: 20,
            q
        }
    })
    return _.map(data.reasons, (v, k) => {
        return { value: v.id, label: v.reason }
    })

}
//************************************  //
export const _getAllExpressPlaylistsTiersWithQ = async (q = '') => {
    const { data } = await API.get('express/all-tiers', {
        params: {
            limit: 20,
            q
        }
    })
    return _.map(data.tiers, (v, k) => {
        return { value: v.id, label: v.tier_name }
    })
}
export const _getAllExpressPlaylistsStatusesWithQ = async (q = '') => {
    const { data } = await API.get('express/playlist/statuses', {
        params: {
            limit: 20,
            q
        }
    })
    return _.map(data.statuses, (v, k) => {
        return { value: v.id, label: v.status }
    })
}
export const _getAllExpressPlaylistsDurationsWithQ = async (q = '') => {
    const { data } = await API.get('express/all-durations', {
        params: {
            limit: 20,
            q
        }
    })
    return _.map(data.durations, (v, k) => {
        return { value: v.id, label: v.duration }
    })
}

//************************************//
export const _editExpressPlayList = (data, callback, callbackErr) => {
    API_WithSwal.put(`express/playlist/edit/${data.id}`, data)
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
export const _approveAdminExpressPlaylistCampaign = (data, callback) => {
    console.log(`approve campaign ${data}`)
    API_WithSwal.post(`express/approve/campaign`, data)
        .then(function () {
            callback()
        })
        .catch(function ({ data }) { })
}
//****************************** *//
export const _checkPlaylistIfIsValid = (link, callback, callbackErr) => {
    const valid = 'https://open.spotify.com/playlist/'
    console.log("Received link:", link)
    if (_.startsWith(link, valid)) {
        API.post(`express/playlist/info`, {
            spotify_url: link
        })
            .then(({ data }) => callback({ code: 1, ...data }))
    } else {
        callback({ code: 2 })
    }
}
export const _checkPlaylistIfIsExists = (id, callback, callbackErr) => {
    API.post(`express/playlist/exists`, {
        playlist: id
    })
        .then(({ data }) => callback({ data }))
}
// export const _getPlaylistInfo = (link, callback,callbackErr) => {
//     console.log("Received link:", link)
//     const response = API.post(`express/playlist/info`, {
//         spotify_url : link
//     })
//     .then(({data}) => callback({data}))
// }
// export const _getPlaylistInfo = async (id) => {
//    const response = await API.get(`spotify/get-playlist?playlist_id=${id}`)
//     return response.data.playlist    
// }
//***************** Express Tiers *******************//
export const _addExpressDuration = (data, callback, callbackErr) => {
    API_WithSwal.post(`express/durations`, data)
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
//************************************//
export const _editExpressDuration = (data, callback, callbackErr) => {
    API_WithSwal.put(`express/durations/${data.id}`, data)
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
//***************** Express Tiers *******************//
export const _addExpressTier = (data, callback, callbackErr) => {
    API_WithSwal.post(`express/tiers`, data)
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
//************************************//
export const _editExpressTier = (data, callback, callbackErr) => {
    API_WithSwal.put(`express/tiers/${data.id}`, data)
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
//************************************//
export const _getAllExpressTiersStatusesWithQ = async (q = '') => {
    const { data } = await API.get('express/tier/statuses', {
        params: {
            limit: 20,
            q
        }
    })
    return _.map(data.statuses, (v, k) => {
        return { value: v.id, label: v.status }
    })
}
//***************** Express Playlist View ******************* *//
export const _getExpressPlaylistDetails = (id, callback) => {
    API.get(`express/show/playlist/${id}`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
export const _getPerformanceChartStatsAdmin = (id, callback) => {
    API.get(`express/playlist/campaigns/performance/${id}`)
        .then(function ({ data }) {
            callback(data.playlist)
        })
        .catch(function ({ data }) {
        })
}
//***************** Tiers *******************//

export const _addTier = (data, callback, callbackErr) => {
    API_WithSwal.post(`tiers`, data)
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
//************************************//
export const _editTier = (data, callback, callbackErr) => {
    API_WithSwal.put(`tiers/${data.id}`, data)
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
//************************************//
export const _deleteTier = (id, callback) => {
    API.delete(`tiers/${id}`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
//************************************//
export const _getAllTiersWithQ = async (q = '', type) => {
    const { data } = await API.get('tier/getAllTiersWithQ', {
        params: {
            limit: 20,
            q,
            type
        }
    })
    return _.map(data.tiers, (v, k) => {
        return { value: v.id, label: `${v.name} - $${v.price}` }
    })
}
//************************************//
export const _getAllTiers = (q = '', type, callback) => {
    API.get('tier/getAllTiersWithQ', {
        params: {
            limit: 100,
            q,
            type
        }
    }).then(({ data }) => {
        callback(data.tiers)
    })
}

//***************** Users *******************//

//************************************//
export const _approveUser = (id, callback) => {
    API.delete(`user/${id}/approve`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
//************************************//
export const _declineUser = (id, callback) => {
    API.delete(`user/${id}/decline`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
//************************************//
export const _getUserInfo = (id, callback) => {
    API.get(`users/${id}`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
//************************************//
export const _getArtistInfo = (id, callback) => {
    API.get(`users/${id}`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
//************************************//
export const _addUser = (data, callback, callbackErr) => {
    API_WithSwal.post(`users`, data)
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
//************************************//
export const _editUser = (data, callback, callbackErr) => {
    API_WithSwal.put(`users/${data.id}`, data)
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
//************************************//
export const _deleteUser = (id, callback) => {
    API.delete(`users/${id}`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
//************************************//
export const _getAllArtistsWithQ = async (q = '') => {
    const { data } = await API.get('user/getAllArtistsWithQ', {
        params: {
            limit: 20,
            q
        }
    })
    return _.map(data.users, (v, k) => {
        return { value: v.id, label: v.name }
    })
}
//************************************//
export const _getAllCuratorsWithQ = async (q = '') => {
    const { data } = await API.get('user/getAllCuratorsWithQ', {
        params: {
            limit: 20,
            q
        }
    })
    return _.map(data.users, (v, k) => {
        return { value: v.id, label: v.name }
    })
}
//************************************//
export const _getAllCuratorVsCampaignStatusWithQ = async (q = '') => {
    const { data } = await API.get('statuses/getAllCuratorVsCampaignStatusWithQ', {
        params: {
            limit: 20,
            q
        }
    })
    return _.map(data.statuses, (v, k) => {
        return { value: v.id, label: v.name }
    })
}

//***************** Genres *******************//

export const _addUserType = (data, callback, callbackErr) => {
    API_WithSwal.post(`userTypes`, data)
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
//************************************//
export const _editUserType = (data, callback, callbackErr) => {
    API_WithSwal.put(`userTypes/${data.id}`, data)
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
//************************************//
export const _deleteUserType = (id, callback) => {
    API.delete(`userTypes/${id}`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}

//***************** Interests *******************//

export const _addInterest = (data, callback, callbackErr) => {
    API_WithSwal.post(`interests`, data)
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
//************************************//
export const _editInterest = (data, callback, callbackErr) => {
    API_WithSwal.put(`interests/${data.id}`, data)
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
//************************************//
export const _deleteInterest = (id, callback) => {
    API.delete(`interests/${id}`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}


//************************************//
export const _getAllUserTypesWithQ = async (q = '') => {
    const { data } = await API.get('userType/getAllUserTypesWithQ', {
        params: {
            limit: 20,
            q
        }
    })
    return _.map(data.userTypes, (v, k) => {
        return { value: v.id, label: v.name }
    })
}


//************************************//
export const _getAllCampaignTypesWithQ = async (q = '') => {
    const { data } = await API.get('campaignType/getAllCampaignTypesWithQ', {
        params: {
            limit: 20,
            q
        }
    })
    return _.map(data.campaignTypes, (v, k) => {
        return { value: v.id, label: v.name }
    })
}

//***************** Languages *******************//

//************************************//
export const _getAllLanguagesWithQ = async (q = '') => {
    const { data } = await API.get('language/getAllLanguagesWithQ', {
        params: {
            limit: 20,
            q
        }
    })
    return _.map(data.languages, (v, k) => {
        return { value: v.id, label: v.name }
    })
}


//***************** Campaigns *******************//

export const _getCampaignInfo = (id, callback) => {
    API.get(`campaigns/${id}`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
//************************************//
export const _addCampaign = (data, callback, callbackErr) => {
    const formData = new FormData()
    _.each(data, (x, key) => {
        if (key === 'media_link' && !_.isString(x)) {
            if (!_.isEmpty(x)) {
                formData.append(key, x[0])
            }
        } else {
            if (x) {
                formData.append(key, x)
            }
        }
    })

    if (process.env.REACT_APP_AUTH_MODULE === 'artist') {
        Artist_API_WithSwal.post(`artist/campaigns`, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then(function () {
                callback()
            })
            .catch(function (data) {
                callbackErr(data?.data?.errors)
            })
    } else {
        API_WithSwal.post(`campaigns`, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then(function () {
                callback()
            })
            .catch(function (data) {
                callbackErr(data?.data?.errors)
            })
    }
}
//************************************//
export const _editCampaign = (data, callback, callbackErr) => {
    const formData = new FormData()
    _.each(data, (x, key) => {
        if (key === 'media_link') {
            if (!_.isString(x)) {
                formData.append(key, x[0])
            }
        } else {
            formData.append(key, x)
        }
    })
    if (process.env.REACT_APP_AUTH_MODULE === 'artist') {
        Artist_API_WithSwal.post(process.env.REACT_APP_AUTH_MODULE === 'artist' ? `artist/campaign/update/${data.id}` : `campaign/update/${data.id}`, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then(function () {
                callback()
            })
            .catch(function (data) {
                callbackErr(data?.data?.errors)
            })
    } else {
        API_WithSwal.post(process.env.REACT_APP_AUTH_MODULE === 'artist' ? `artist/campaign/update/${data.id}` : `campaign/update/${data.id}`, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then(function () {
                callback()
            })
            .catch(function (data) {
                callbackErr(data?.data?.errors)
            })
    }
}
//************************************//
export const _deleteCampaign = (id, callback) => {
    API_WithSwal.delete(`campaigns/${id}`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
// //************************************//
// export const _checkUriUrl = (link, callback) => {
//     if (_.startsWith(link, 'https://open.spotify.com/track/')) {
//         const id = link.split('')[link.split('').length - 1]
//         SPOTIFY_API.get(`tracks/${id}`, {headers: {Authorization: `Bearer ${localStorage.getItem('spotify_token')}`}})
//             .then((data) => callback(data))
//     }
// }
//************************************//
export const _checkUriUrl = (link, callback) => {
    const valid = 'https://open.spotify.com/track/'
    if (_.startsWith(link, valid)) {
        const track_id = link.substr(valid.length)
        SPOTIFY_API.get(`get-track`, {
            params: {
                track_id
            }
        })
            .then(({ data }) => callback({ code: 1, other: data?.track }))
    } else {
        callback({ code: 2 })
    }
}
//************************************//
// export const _checkPlaylistIfTrackIsValid = (link, callback) => {
//
//     if (_.startsWith(link, 'https://open.spotify.com/playlist/')) {
//         const id = link.split('')[link.split('').length - 1]
//         SPOTIFY_API.get(`playlists/${id}`, {headers: {Authorization: `Bearer ${localStorage.getItem('spotify_token')}`}})
//           .then((data) => callback(data))
//     }
// }
export const _checkPlaylistIfTrackIsValid = (link, callback) => {

    if (_.startsWith(link, 'https://open.spotify.com/playlist/')) {
        const playlist_id = link.split('/')[link.split('/').length - 1]
        SPOTIFY_API.get(`get-playlist`, {
            params: {
                playlist_id
            }
        })
            .then(({ data }) => callback({ code: 1, ...data?.playlist }))
    } else {
        callback({ code: 2 })
    }
}
//************************************//
export const _publishCampaign = (id, callback) => {
    API.post(`campaign/${id}/publish`, { publish_date: '2022-10-10' })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
//************************************//
export const _approveCampaign = (id, callback) => {
    API_WithSwal.delete(`campaign/${id}/approve`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
//************************************//
//   export const _declineCuratorVsCampaign = (data, callback) => {
//     API_WithSwal.post(`curator_vs_campaign/${data.id}/decline`, {decline_reason: data.decline_reason})
//         .then(function ({ data }) {
//             callback(data)
//         })
//         .catch(function ({ data }) {
//         })
// }
export const _declineCampaign = (data, callback) => {
    API_WithSwal.post(`campaign/${data.id}/decline`, {decline_reason: data.decline_reason})
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
//************************************//
export const _storeCampaignBudget = (data, callback) => {
    API.post(`campaign/${data.id}/storeBudget`, data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}

//***************** Payouts *******************//

//************************************//
export const _getPayoutInfo = (id, callback) => {
    API.get(`payouts/${id}`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
//************************************//
export const _payPayout = (data, callback, callbackErr) => {
    const formData = new FormData()
    _.each(data, (x, key) => {
        if (key === 'attachment_link') {
            if (!_.isString(x)) {
                formData.append(key, x[0])
            }
        } else {
            formData.append(key, x)
        }
    })
    API_WithSwal.post(`payout/${data.id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" }
    })
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}

//************************************//
export const _payPayoutWithWise = (id, callback, callbackErr) => {
    API.post(`payout/pay/wise/${id}`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
//***************** Wise Payouts *************************//
export const _payWiseTransfers = (id, callback, callbackErr) => {
    API_WithSwal.get(`/wise/make/transfer/fund/${id}`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
//view transfer details
//************************************//
export const _getTransferInfo = (id, callback) => {
    API.get(`wise/admin/transfer/view/${id}`)
        .then(function ({data}) {
            callback(data)
        })
        .catch(function ({data}) {
        })
  }
  //npm install file-saver to install file saver
  export const _getDownloadPDFWise = (id, callback) => {
    API.get(`wise/admin/pdf/${id}`, { responseType: 'arraybuffer' })
      .then(function (response) {
        const filename = 'receipt.pdf'
        const contentType = 'application/pdf'
        
        const blob = new Blob([response], { type: contentType })
        console.log(blob)
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          // For Internet Explorer
          window.navigator.msSaveBlob(blob, filename)
        } else {
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = filename
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          URL.revokeObjectURL(url)
        }
        callback(response)
      })
      .catch(function (error) {
        console.error('Error occurred while downloading PDF', error)
      })
  }
// export const _getDownloadPDFWise = (id, callback) => {
//     API.get(`wise/admin/pdf/${id}`)
//       .then(function (response) {
//         const blob = new Blob([response], { type: 'application/pdf' })
//         const url = URL.createObjectURL(blob)
//         const a = document.createElement('a')
//         a.href = url
//         a.download = 'wise_transfer_receipt.pdf'
//         a.click()
//         callback(response)
//       })
//       .catch(function (error) {
//         console.error('Error occurred while downloading PDF', error)
//       })
//   }
//Invoices Dash stats
export const _getWiseTransferStats = (callback) => {
    API.get('wise/admin/transfer/stats')
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
//***************** Invoices *******************//

//************************************//
export const _getInvoiceInfo = (id, callback) => {
    API.get(`invoices/${id}`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
//************************************//
export const _payInvoice = (data, callback, callbackErr) => {
    const formData = new FormData()
    _.each(data, (x, key) => {
        if (key === 'attachment_link') {
            if (!_.isString(x)) {
                formData.append(key, x[0])
            }
        } else {
            formData.append(key, x)
        }
    })
    API_WithSwal.post(`invoice/${data.id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" }
    })
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}

//***************** Curators Vs Campaigns *******************//

export const _addCuratorVsCampaing = (data, callback, callbackErr) => {
    API_WithSwal.post(`curators_vs_campaigns`, data)
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
//************************************//

export const _addAdminVsCampaign = (data, callback, callbackErr) => {
    API_WithSwal.post(`campaign/admins_vs_campaigns/${data.id}`, data)
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
//************************************//

export const _editCuratorVsCampaign = (data, callback, callbackErr) => {
    API_WithSwal.put(`curators_vs_campaigns/${data.id}`, data)
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
//************************************//

export const _storeCuratorVsCampaignPlaylist = (data, callback, callbackErr) => {
    API_WithSwal.put(`curator_vs_campaign/playlist/${data.id}`, data)
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
//************************************//

export const _storeCuratorVsCampaignPlaylistPositioning = (data, callback, callbackErr) => {
    API.put(`curator_vs_campaign/playlist/positioning/${data.id}`, data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
//************************************//

export const _resetPassword = (user_id, callback, callbackErr) => {
    API_WithSwal.post(`reset-password`, { user_id })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
//************************************//

export const _inviteUser = (user_id, callback, callbackErr) => {
    API_WithSwal.post(`invite-user`, { user_id })
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
//************************************//
export const _deleteCuratorVsCampaignPlaylistPositioning = (id, callback = () => { }, callbackErr = () => { }) => {
    API_WithSwal.delete(`curator_vs_campaign/position-history/destroy/${id}`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
//************************************//
export const _approveCuratorVsCampaign = (id, callback) => {
    API.delete(`curator_vs_campaign/${id}/approve`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
//************************************//
export const _declineCuratorVsCampaign = (data, callback) => {
    API_WithSwal.post(`curator_vs_campaign/${data.id}/decline`, {decline_reason: data.decline_reason})
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
//************************************//
export const _completeCuratorVsCampaign = (id, callback) => {
    API.delete(`curator_vs_campaign/${id}/complete`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
//************************************//
export const _changeCuratorVsCampaignStatus = (id, status, callback) => {
    API.delete(`curator_vs_campaign/change_status/${id}/${status}`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
//************************************//
export const _getDashboardSummary = (callback, callbackErr) => {
    API.get(`campaigns/getSummary`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
            // callbackErr(data.errors)
        })
}
//************************************//
export const _getPaymentSummary = (callback, callbackErr) => {
    API.get(`campaigns/paymentSymmary`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
            // callbackErr(data.errors)
        })
}

export const _getFormInfo = (id, callback) => {
    API.get(`get_form/${id}`)
        .then(function (data) {
            callback(data)
        })
        .catch(function (data) {
        })
}

export const _addForm = (data, callback, callbackErr) => {
    const formData = new FormData()
    _.each(data, (x, key) => {
        if (key === 'media_link' && !_.isString(x)) {
            if (!_.isEmpty(x)) {
                formData.append(key, x[0])
            }
        } else {
            if (x) {
                formData.append(key, x)
            }
        }
    })
    API_WithSwal.post(`new_form`, formData, {
        headers: { "Content-Type": "multipart/form-data" }
    })
        .then(function () {
            callback(data)
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}

//************************************//
export const _editForm = (data, callback, callbackErr) => {
    API_WithSwal.post(`forms/${data.id}`, data)
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}

export const _deleteForm = (id, callback) => {
    API.delete(`forms/${id}`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}

export const _getRefs = (id, callback) => {
    API.get(`references/${id}`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
//users menu tab users list stats
export const _getUsersStat = (callback) => {
    API.get('users/statistics-data')
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}
//Campaigns menu tab list stats
export const _getCampaignsStat = (callback) => {
    API.get('campaigns/statistics-data')
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}

//Invoices Dash stats
export const _getInfluencerStat = (callback) => {
    API.get('dashboard/statistics-data')
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}

//Artist
//get min tier price
export const _getExpressArtistMinTier = (callback, callbackErr) => {
    API.get(`express/artist/min/tier`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (error) {
            // callbackErr(data?.data?.errors)
            const data = error?.response?.data
            if (data) {
                const { data: responseData } = data
                callbackErr(responseData?.errors)
            } else {
                console.log('Error:', error)
            }
        })
}

/******************** Campaigns *******************/
export const _getExpressCampaignInfo = (id, callback) => {
    API.get(`express/express-campaigns/${id}`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function ({ data }) {
        })
}

export const _declineExpressCampaign = (data, callback, callbackErr) => {
    API_WithSwal.post(`express/decline/campaign`, data)
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
/******************** Stripe *******************/
export const _stripePaymentCampaignSuccess = (data, callback, callbackErr) => {
    API.post(`stripe/campaign/checkout/check/pay`, data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
//express artist
export const _storeArtistExpressPlaylistCampaign = (data, callback, callbackErr) => {
    API_WithSwal.post(`express/artist/campaign/create`, data)
        .then(function () {
            callback()
        })
        .catch(function (data) {
            callbackErr(data?.data?.errors)
        })
}
/********* Tiktok Released Campaign ***********/
export const _checkTiktokMusic = (link, callback) => {
    const valid = 'https://www.tiktok.com/music/'
    if (_.startsWith(link, valid)) {
        const music_id = link.substr(valid.length)
        API.get(`check-tiktok-music/${music_id}`)
            .then((data) => {
                console.log(data)
                callback({ code: 1, data })
            })
    } else {
        callback({ code: 2 })
    }
}
export const _checkTiktokVideo = (link, callback) => {
    const formData = new FormData()
    formData.append('video_link', link)
  
    API.post('check-tiktok-video', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((response) => {
        console.log(`response ${response}`)
        if (response) {
          callback({ code: 1, data: response })
        } else {
          callback({ code: 2 })
        }
      })
      .catch((error) => {
        console.error(error)
        callback({ code: 2 })
      })
}