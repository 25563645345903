import React from "react"
import {
    Bell,
    Briefcase,
    Shield,
    Home,
    Lock,
    User,
    Users,
    Play,
    Music,
    Codesandbox, CheckCircle, Settings
} from "react-feather"

import {trans} from '@utils'

export const Navigation = [
    {
        header: trans('user.nav.scraping_tool'),
        action: 'call',
		resource: 'general'
    },
    {
        id: 'scrapingJobs',
        title: trans('scraping.nav.scrapingJobs'),
        icon: <Play/>,
        navLink: '/scraping-jobs/lists',
        action: 'call',
        resource: 'view.main.scraping.tab.list'
    }
    // {
    //     id: 'crm',
    //     title: trans('user.nav.artists_crm'),
    //     icon: <Users/>,
    //     navLink: '/crm/list',
    //     action: 'call',
    //     resource: 'SCRAPING_VIEW_CRM_LIST'
    // }
    // {
    //     id: 'chat',
    //     title: 'Admins',
    //     icon: <Shield />,
    //     navLink: '/apps/demo'
    // },
    // {
    //     id: 'chat',
    //     title: 'Role & Permissions',
    //     icon: <Shield />,
    //     navLink: '/apps/demo'
    // },

]
