import React from "react"
import {Bell, Briefcase, Shield, File, Music, Search, Home, Lock, Archive, User, Users, ShoppingCart, Activity, FileText, FilePlus, Zap, Star} from "react-feather"
import {trans} from '@utils'
import { Icon } from "@iconify/react"
export {getUserData} from '../utility/Utils'

//if true is artist menu else curator menu
// const loggedUser = getUserData()
const isArtist = true
const userType = localStorage.getItem('user_type_id')
 const artistNavigation = [
    {
        id: 'dashboard',
        // title: trans('user.nav.dashboard'),
        title: `Dashboard`,
        icon: <Home size={20}/>,
        navLink: '/dashboard',
        action: 'call',
        resource: 'ARTIST_ALL_PERMISSION'
    },
    {
        id: 'find-campaigns',
        // title: trans('user.nav.campaigns'),
        title: 'Find Campaigns',
        icon: <Search size={20}/>,
        navLink: '/campaigns/list',
        action: 'call',
        resource: 'CURATOR_PERMISSION'
    },
    {
        id: 'find-campaigns',
        // title: trans('user.nav.campaigns'),
        title: 'Find Custom Campaigns',
        icon: <Search size={20}/>,
        navLink: '/campaigns/list',
        action: 'call',
        resource: 'INFLUENCER_PERMISSION'
    },
    {
        id: 'influ-custom-campaigns',
        // title: trans('user.nav.campaigns'),
        title: 'Custom Campaigns',
        icon: <Briefcase size={20}/>,
        navLink: '/my-custom/campaigns/list',
        action: 'call',
        resource: 'INFLUENCER_PERMISSION'
    },
    
    {
        id: 'Campaigns',
        title: 'Campaigns',
        icon: <Briefcase />,
        children: [
            {
                id: 'add-campaign',
                // title: trans('user.nav.campaigns'),
                title: 'New Campaign',
                icon: <FilePlus size={20}/>,
                navLink: '/select/platform',
                action: 'call',
                resource: 'ARTIST_PERMISSION'
            },
            
           
            // {
            //     id: 'applied-campaigns',
            //     // title: trans('user.nav.myCampaigns'),
            //     title: 'Custom Campaigns',
            //     icon: <Archive size={20}/>,
            //     navLink: '/my-campaigns',
            //     action: 'call',
            //     resource: 'CURATOR_PERMISSION'
            // },
            {
                id: 'express-campaign',
                // title: trans('user.nav.campaigns'),
                title: 'My Express Campaigns',
                icon: <Zap size={20}/>,
                navLink: '/express/campaigns/list',
                action: 'call',
                resource: 'ARTIST_PERMISSION'
            },
           
            {
                id: 'campaigns',
                // title: trans('user.nav.campaigns'),
                title: 'My Custom Campaigns',
                icon: <Star size={20}/>,
                navLink: '/custom/campaigns/list',
                action: 'call',
                resource: 'ARTIST_PERMISSION'
            }
            
        ]
      },
      {
      id: ' My-Campaigns',
      title: 'My Campaigns',
      icon: <Briefcase />,
      children: [
        {
            id: 'express-campaign',
            // title: trans('user.nav.campaigns'),
            title: 'Express Campaigns',
            icon: <Zap size={20}/>,
            navLink: '/express/campaigns/list',
            action: 'call',
            resource: 'CURATOR_PERMISSION'
        },
        {
            id: 'custom-campaign',
            // title: trans('user.nav.campaigns'),
            title: 'Custom Campaigns',
            icon: <Briefcase size={20}/>,
            navLink: '/my-custom/campaigns/list',
            action: 'call',
            resource: 'CURATOR_PERMISSION'
        }
      ]
    },
      
      {
        id: 'my-playlists',
        // title: trans('user.nav.dashboard'),
        title: 'My Playlists',
        icon: <Music size={20}/>,
        navLink: '/express/my/playlists/list',
        action: 'call',
        resource: 'CURATOR_PERMISSION'
    },
    
    {
        id: 'invoices',
        // title: trans('user.nav.invoices'),
        title: 'Invoices',
        icon: <FileText size={20}/>,
        navLink: '/invoices/list',
        action: 'call',
        resource: 'ARTIST_PERMISSION'
    },
    {
        id: 'payouts',
        // title: trans('user.nav.payouts'),
        title: 'Payouts',
        icon: <FileText size={20}/>,
        navLink: '/payouts/list',
        action: 'call',
        resource: 'CURATOR_PERMISSION'
    },
    {
        id: 'payouts',
        // title: trans('user.nav.payouts'),
        title: 'Payouts',
        icon: <FileText size={20}/>,
        navLink: '/payouts/list',
        action: 'call',
        resource: 'INFLUENCER_PERMISSION'
    },
    // {
    //     id: 'forms',
    //     title: 'Forms',
    //     icon: <File size={20}/>,
    //     navLink: '/forms',
    //     action: 'call',
    //     resource: 'ARTIST_PERMISSION'
    // },
    {
        id: 'tickets',
        title: 'Support Tickets',
        icon: <File size={20}/>,
        navLink: '/tickets',
        action: 'call',
        resource: 'ARTIST_ALL_PERMISSION'
    },
           
    {
        id: 'Account',
        title: 'Account',
        icon: <User />,
        children: [
            {
                id: 'profile',
                // title: trans('user.nav.account'),
                title: 'Profile',
                icon: <User size={20}/>,
                navLink: '/account',
                action: 'call',
                resource: 'ARTIST_ALL_PERMISSION'
            },
            {
                id: 'security',
                // title: trans('user.nav.security'),
                title: 'Security',
                icon: <Lock size={20}/>,
                navLink: '/security',
                action: 'call',
                resource: 'EMAIL_OAUTH_TYPE'
            }
        ]
      }
]

// Curator Navigation
const curatorNavigation = [
    {
        id: 'dashboard',
        // title: trans('user.nav.dashboard'),
        title: `Dashboard ${localStorage.getItem('user_type_id')}`,
        icon: <Home size={20}/>,
        navLink: '/dashboard',
        action: 'call',
        resource: 'ARTIST_ALL_PERMISSION'
    },
    
    {
        id: 'Campaigns',
        title: 'Campaigns',
        icon: <Briefcase />,
        children: [
            {
                id: 'campaigns',
                // title: trans('user.nav.campaigns'),
                title: 'Find Campaigns',
                icon: <Search size={20}/>,
                navLink: '/campaigns/list',
                action: 'call',
                resource: 'ARTIST_ALL_PERMISSION'
            },
            {
                id: 'applied-campaigns',
                // title: trans('user.nav.myCampaigns'),
                title: 'Applied Campaigns',
                icon: <Archive size={20}/>,
                navLink: '/my-campaigns',
                action: 'call',
                resource: 'CURATOR_PERMISSION'
            },
            {
                id: 'applied-campaigns1',
                // title: trans('user.nav.myCampaigns'),
                title: 'Applied Campaigns',
                icon: <Archive size={20}/>,
                navLink: '/my-campaigns',
                action: 'call',
                resource: 'INFLUENCER_PERMISSION'
            }
        ]
      },
      {
        id: 'my-playlists',
        // title: trans('user.nav.dashboard'),
        title: 'My Playlists',
        icon: <Music size={20}/>,
        navLink: '/express/my/playlists/list',
        action: 'call',
        resource: 'CURATOR_PERMISSION'
    },
    
    {
        id: 'invoices',
        // title: trans('user.nav.invoices'),
        title: 'Invoices',
        icon: <FileText size={20}/>,
        navLink: '/invoices/list',
        action: 'call',
        resource: 'ARTIST_PERMISSION'
    },
    {
        id: 'payouts',
        // title: trans('user.nav.payouts'),
        title: 'Payouts',
        icon: <FileText size={20}/>,
        navLink: '/payouts/list',
        action: 'call',
        resource: 'CURATOR_PERMISSION'
    },
    {
        id: 'payoutsI',
        // title: trans('user.nav.payouts'),
        title: 'Payouts',
        icon: <FileText size={20}/>,
        navLink: '/payouts/list',
        action: 'call',
        resource: 'INFLUENCER_PERMISSION'
    },
    // {
    //     id: 'forms',
    //     title: 'Forms',
    //     icon: <File size={20}/>,
    //     navLink: '/forms',
    //     action: 'call',
    //     resource: 'ARTIST_PERMISSION'
    // },
    {
        id: 'tickets',
        title: 'Support Tickets',
        icon: <File size={20}/>,
        navLink: '/tickets',
        action: 'call',
        resource: 'ARTIST_ALL_PERMISSION'
    },
           
    {
        id: 'Account',
        title: 'Account',
        icon: <User />,
        children: [
            {
                id: 'profile',
                // title: trans('user.nav.account'),
                title: 'Profile',
                icon: <User size={20}/>,
                navLink: '/account',
                action: 'call',
                resource: 'ARTIST_ALL_PERMISSION'
            },
            {
                id: 'security',
                // title: trans('user.nav.security'),
                title: 'Security',
                icon: <Lock size={20}/>,
                navLink: '/security',
                action: 'call',
                resource: 'ARTIST_ALL_PERMISSION'
            }
        ]
      }
]
// Curator Navigation
const influencerNavigation = [
    {
        id: 'dashboard',
        // title: trans('user.nav.dashboard'),
        title: `Dashboard`,
        icon: <Home size={20}/>,
        navLink: '/dashboard',
        action: 'call',
        resource: 'ARTIST_ALL_PERMISSION'
    },
    
    {
        id: 'Campaigns',
        title: 'Campaigns',
        icon: <Briefcase />,
        children: [
            {
                id: 'campaigns',
                // title: trans('user.nav.campaigns'),
                title: 'Find Campaigns',
                icon: <Search size={20}/>,
                navLink: '/campaigns/list',
                action: 'call',
                resource: 'ARTIST_ALL_PERMISSION'
            },
            {
                id: 'applied-campaigns',
                // title: trans('user.nav.myCampaigns'),
                title: 'Applied Campaigns',
                icon: <Archive size={20}/>,
                navLink: '/my-campaigns',
                action: 'call',
                resource: 'ARTIST_ALL_PERMISSION'
            }
        ]
      }, 
    
    {
        id: 'payouts',
        // title: trans('user.nav.payouts'),
        title: 'Payouts',
        icon: <FileText size={20}/>,
        navLink: '/payouts/list',
        action: 'call',
        resource: 'ARTIST_ALL_PERMISSION'
    },
   
    {
        id: 'tickets',
        title: 'Support Tickets',
        icon: <File size={20}/>,
        navLink: '/tickets',
        action: 'call',
        resource: 'ARTIST_ALL_PERMISSION'
    },
           
    {
        id: 'Account',
        title: 'Account',
        icon: <User />,
        children: [
            {
                id: 'profile',
                // title: trans('user.nav.account'),
                title: 'Profile',
                icon: <User size={20}/>,
                navLink: '/account',
                action: 'call',
                resource: 'ARTIST_ALL_PERMISSION'
            },
            {
                id: 'security',
                // title: trans('user.nav.security'),
                title: 'Security',
                icon: <Lock size={20}/>,
                navLink: '/security',
                action: 'call',
                resource: 'ARTIST_ALL_PERMISSION'
            }
        ]
      }
]
console.log(`user type ${userType}`)
//   // Select the appropriate navigation based on the user type
const navigation =  artistNavigation  

export { navigation as Navigation }
