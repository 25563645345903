import { Fragment, useEffect, useMemo, useState } from 'react'
import { ArrowLeft, ArrowRight, Plus, Trash } from 'react-feather'
import { Controller, useForm } from "react-hook-form"
import _ from "lodash"
import { Button, Col, Form, Alert, FormGroup, FormFeedback, Input, Label, Row } from "reactstrap"
import { trans } from '@utils'

import { ErrorMessages } from '@src/components'
import Repeater from '@components/repeater'

import classnames from "classnames"
import { SlideDown } from "react-slidedown"
import { _getRefs } from "../../redux/actions"
import { useParams } from "react-router-dom"


const ArtistDetails = (props) => {
    //const [ref, setRef] = useState([])

    const { errors, handleSubmit, control, trigger, getValues, setValue, register, watch } = useForm()
    const { formData, setFormData } = props.formState
    const { reference, setReference } = props.refState

    const [count, setCount] = useState(1)
    const [memo, setMemo] = useState(1)
    console.log(useParams())
    const formId = props.form_id
    // if (useParams()) {
    //     {formId} = useParams()
    // } else {
    //     {formId} = props.formState.id
    // }
    const form_id = useParams().formId

    if (props.isEditAction) {
        useEffect(() => {
            _getRefs(
                formId,
                (data) => {
                    //console.log(data)
                    setReference(data.reference)
                    setMemo(memo + 1)
                }
            )
        }, [])
    }
    function removeNull(array) {
        return array.filter(x => x !== null)
    }
    useMemo(() => {
        setCount(reference?.length)
        //console.log(reference)
        //removeNull(reference)
        //console.log(Object.keys(references).length)
    }, [memo])

    const increaseCount = () => {
        setCount(count + 1)
    }
    //const [i, setI] = useState(0)
    const { references, setReferences } = useState([{ id: null, reference: null, form_id: null }])
    const handleFormChange = (index, event) => {
        if (props.isEditAction) {
            const data = [...reference]
            data[index] = { id: event.target.id, reference: event.target.value, form_id }
            // data[index] = {"id": event.target.id, "reference": event.target.value, "form_id": {formId}}
            //data[index] = event.target.value
            //console.log(data)
            //console.log('data')
            setReference(data.filter(Boolean))
        } else {
            const data = [...reference]
            data[index] = event.target.value
            //console.log(data)
            //console.log('data')
            setReference(data)
        }
    }

    const deleteForm = (i, event) => {
        event.preventDefault()
        const data = [...reference]
        data.splice(i, 1)
        setReference(data)
        const slideDownWrapper = event.target.closest('.react-slidedown'),
            form = event.target.closest('form')
        if (slideDownWrapper) {
            slideDownWrapper.remove()
        } else {
            form.remove()
        }
        //setCount(reference.length)
    }

    const onSubmit = (data) => {
        trigger()
        if (_.isEmpty(errors)) {
            setFormData(prevState => ({
                ...prevState,
                ...data,
                promotion: data.promotion,
                previous_achievements: data.previous_achievements,
                distributor: data.distributor,
                upc_code: data.upc_code,
                isrc_code: data.isrc_code,
                reference
            }))
            props.stepper.next()
        }
    }
    return (
        <Fragment>
            <Form onSubmit={handleSubmit(onSubmit)}>

                <FormGroup>
                    <Label className='form-label' for='promotion'>
                        Promotion around Release (live shows, music video, press, radio, etc) <small className={'text-danger'}>*</small>
                    </Label>
                    <Controller
                        as={Input}
                        control={control}
                        autoFocus
                        type='text'
                        id='promotion'
                        name='promotion'
                        invalid={errors.promotion && true}
                        rules={{
                            required: trans('user.validation.required')
                        }}
                        defaultValue={formData?.promotion ?? ''}
                        className={classnames({ 'is-invalid': errors['name'] || _.get(props.valErrors, 'name') })}
                    />
                    <ErrorMessages valErrors={props.valErrors} errors={errors} name={'promotion'} />
                </FormGroup>
                <FormGroup>
                    <Label className='form-label' for='previous_achievements'>
                        Previous Achievements <small className={'text-danger'}>*</small>
                    </Label>
                    <Controller
                        as={Input}
                        control={control}
                        autoFocus
                        type='text'
                        invalid={errors.previous_achievements && true}
                        id='previous_achievements'
                        name='previous_achievements'
                        rules={{
                            required: trans('user.validation.required')
                        }}
                        defaultValue={formData?.previous_achievements ?? ''}
                        className={classnames({ 'is-invalid': errors['name'] || _.get(props.valErrors, 'name') })}
                    />
                    <ErrorMessages valErrors={props.valErrors} errors={errors} name={'previous_achievements'} />
                </FormGroup>
                <FormGroup>
                    <Label className='form-label' for='distributor'>
                        Distributor <small className={'text-danger'}>*</small>
                    </Label>
                    <Controller
                        as={Input}
                        control={control}
                        autoFocus
                        type='text'
                        id='distributor'
                        invalid={errors.distributor && true}
                        name='distributor'
                        rules={{
                            required: trans('user.validation.required')
                        }}
                        defaultValue={formData?.distributor ?? ''}
                        className={classnames({ 'is-invalid': errors['name'] || _.get(props.valErrors, 'name') })}
                    />
                    <ErrorMessages valErrors={props.valErrors} errors={errors} name={'distributor'} />
                </FormGroup>
                <FormGroup>
                    <Label className='form-label' for='upc_code'>
                        UPC Code (from Distributor) <small className={'text-danger'}>*</small>
                    </Label>
                    <Controller
                        as={Input}
                        control={control}
                        autoFocus
                        type='number'
                        id='upc_code'
                        name='upc_code'
                        invalid={errors.upc_code && true}
                        rules={{
                            required: trans('user.validation.required')
                        }}
                        defaultValue={formData?.upc_code ?? ''}
                        className={classnames({ 'is-invalid': errors['name'] || _.get(props.valErrors, 'name') })}
                    />
                    <ErrorMessages valErrors={props.valErrors} errors={errors} name={'upc_code'} />
                </FormGroup>
                <FormGroup>
                    <Label className='form-label' for='isrc_code'>
                        ISRC Code (from Distributor)
                    </Label>
                    <Controller
                        as={Input}
                        control={control}
                        autoFocus
                        type='text'
                        id='isrc_code'
                        name='isrc_code'
                        invalid={errors.isrc_code && true}
                        rules={{
                            required: false
                        }}
                        defaultValue={formData?.isrc_code ?? ''}
                        className={classnames({ 'is-invalid': errors['name'] || _.get(props.valErrors, 'name') })}
                    />
                    <ErrorMessages valErrors={props.valErrors} errors={errors} name={'isrc_code'} />
                </FormGroup>
                <div>
                    <h4>References</h4>
                    <Repeater count={count}>
                        {i => {
                            const Tag = i === 0 ? 'div' : SlideDown
                            return (
                                <Tag key={i}>
                                    <Form>
                                        <Row className='justify-content-between align-items-center'>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for={`animation-item-name-${i}`}> {i + 1}. Reference</Label>
                                                    {props.isEditAction ? (
                                                        <Input type='text' value={reference[i]?.reference} id={reference[i]?.id} placeholder="Reference" onChange={event => handleFormChange(i, event)} name='reference' />
                                                    ) : (
                                                        <Input type='text' id={`animation-item-name-${i}`} placeholder="Reference" onChange={event => handleFormChange(i, event)} />
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <Button.Ripple color='flat-danger' className='btn-icon' onClick={event => deleteForm(i, event)} outline>
                                                    <Trash size={16} />
                                                </Button.Ripple>
                                            </Col>
                                            {/*<Col sm={12}>*/}
                                            {/*    <hr />*/}
                                            {/*</Col>*/}
                                        </Row>
                                    </Form>
                                </Tag>
                            )
                        }}
                    </Repeater>
                    <br />
                    {count > 4 ? (
                        <Alert color='warning'>
                            <div className='alert-body'>
                                You cannot add more than 5 references
                            </div>
                        </Alert>
                    )
                        : (
                            <Button.Ripple className='btn-icon round' outline color='primary' onClick={increaseCount} disabled={count > 4} >
                                <Plus size={14} />
                                <span className='align-middle ml-25'>Add Reference</span>
                            </Button.Ripple>
                        )}

                </div>


                <div className='d-flex justify-content-between mt-2'>
                    <Button color='primary' onClick={() => props.stepper.previous()}>
                        <ArrowLeft size={14} className='rotate-rtl align-middle me-sm-50 me-0' />
                        <div className='align-middle d-sm-inline-block d-none'>Previous</div>
                    </Button>
                    <Button color='primary' type='submit' >
                        <div className='align-middle d-sm-inline-block d-none'>Next</div>
                        <ArrowRight size={14} className='rotate-rtl align-middle ms-sm-50 ms-0' />
                    </Button>
                </div>
            </Form>
        </Fragment>
    )
}

export default ArtistDetails