import React, { Fragment, Component, memo } from 'react'
import { connect } from 'react-redux'
import {FileText, MoreVertical, Send, FilePlus, Key, User, Trash, Eye, AlertCircle} from 'react-feather'
import {
  Row,
  Col,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, Badge, ButtonGroup
} from 'reactstrap'
import { withRouter, Link } from 'react-router-dom'
//************************************//
import {getUserData} from "../../utility/Utils"
import Breadcrumbs from '@src/components/breadcrumbs'
import DataTable from '@src/components/datatable'
import {trans, _confirm, convertUnicodeToSymbols, _successSwal} from '@utils'
import {AbilityContext, _hasAnyAbility } from '@src/utility/context/Can'
import Avatar from '@components/avatar'
import {_getAllRolesWithQ} from '@modules/rolespermissions'
import {CanCall} from '@authModule'
//************************************//
import {_deleteCampaign, _deleteGenre, _getCampaignsStat, _stripePaymentCampaignSuccess, _storeArtistExpressPlaylistCampaign} from '../../redux/actions'
import BasicInfoModal from './BasicInfoModal'
import {statusesColors} from "../../../../utility/Constants"
import BasicInfoWizardModal from "./BasicInfoWizardModal"
import _ from "lodash"

import { ShimmerThumbnail } from "react-shimmer-effects"
import StatsCard from "./StatsCard"
//************************************//

const tableColumns = (state, _editBasicInfoModal, _deleteUser, hasAction) => [
  {
    name: 'ID',
    selector: 'id',
    sortable: false,
    omit: true,
    filter: {
      enabled: false
    }
  },
  {
    name: 'Type',
    selector: 'name',
    cell: (row) => {
      return (
          row.campaign_type_id === 1 ? <img width={22} src={require('@fwsrc/assets/images/spotify.png').default} /> : <img width={22} src={require('@fwsrc/assets/images/tiktok.png').default} />
      )
    }
  },
  {
    name: trans('user.name'),
    selector: 'name',
    sortable: true,
    minWidth: '200px',
    cell: (row, index, column, id) => {

      //convert the HTML unicodes in the name if any
      const campaignName = convertUnicodeToSymbols(row.name)
      return (
            <div className='user-info text-truncate'>
              <span className='d-block font-weight-bold text-truncate'>
                {campaignName}

                {/* Additional information alert for artist */}
                {
                  ((row?.form === null) && row?.invoice?.is_paid && state?.isArtist && !row.is_released) ? 
                  (<AlertCircle color='orange' size={18} style={{marginBottom: 2, marginLeft:2}}/>) : null
                }
              </span>
            </div>
      )
    }
  },
  {
    name: trans('user.name'),
    selector: 'name',
    sortable: true,
    minWidth: '225px',
    omit:true,
    filter: {
      enabled: true,
      type: 'text'
    }
  },
  {
    name: !state.isArtist ? 'Artist Name' : null,
    selector: 'artist_name',
    sortable: false,
    filter: {
      enabled: true,
      type: 'text'
    },
    cell: (row) => {
      if (!state.isArtist) {
        return (
            <span>{row.artist?.name}</span>
        )
      } else {
        return null
      }
    }
  },
  {
    name: 'Tier',
    selector: 'tier',
    sortable: false,
    minWidth: '150px',
    cell: (row) => {
      return <Badge color={'info'}>{row?.tier?.name}</Badge>
    }
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: false,
    minWidth: '150px',
    cell: (row) => {
      return <Badge color={statusesColors[row.status].color}>{row.status}</Badge>
    }
  },
  {
    name: 'Invoice Status',
    selector: 'status',
    minWidth: '150px',
    sortable: false,
    omit: state.user.user_type_id > 1,
    cell: (row) => {
      return <Badge color={row.invoice ? row.invoice.is_paid ? 'success' : 'warning' : 'warning'}>{row.invoice?.id > 0 ? row.invoice?.is_paid ? 'Paid' : 'Pending' : 'Pending'}</Badge>
    }
  },
  {
    name: trans('gen.actions.actions'),
    allowOverflow: true,
    // omit: !hasAction,
    cell: (row, index, column, id) => {
      return (
        <div className='d-flex'>
          <UncontrolledDropdown>
            <DropdownToggle className='pr-1' tag='span'>
              <MoreVertical size={15} />
            </DropdownToggle>
            <DropdownMenu right>
              <CanCall action='custom.campaigns.show' id={`updateUser_${row.id}`}>
                <Link to={`/campaigns/${row.id}`} className='w-100 dropdown-item'>
                  <Eye size={15}/>
                  <span className='align-middle ml-50'>View</span>
                </Link>
              </CanCall>
              {
                !state.isArtist && (
                  <>
                    <CanCall action='custom.campaigns.edit' id={`updateUser_${row.id}`}>
                      <DropdownItem className='w-100' onClick={e => _editBasicInfoModal(row)}>
                        <FileText size={15} />
                        <span className='align-middle ml-50'>{trans('gen.actions.edit')}</span>
                      </DropdownItem>
                    </CanCall>
                    <CanCall action='custom.campaigns.delete' id={`deleteUser_${row.id}`}>
                      <DropdownItem className='w-100 btn-flat-danger' onClick={e => _deleteUser(row.id)} disabled={statusesColors[row.status].id > 1}>
                        <Trash size={15}/>
                        <span className='align-middle ml-50'>{trans('gen.actions.delete')}</span>
                      </DropdownItem>
                    </CanCall>
                  </>
                )
              }
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      )
    }
  }
]
const tableActions = ['INFLUENCER_VIEW_CAMPAIGN_PROFILE', 'INFLUENCER_EDIT_CAMPAIGN', 'INFLUENCER_DELETE_CAMPAIGN']
//************************************//
class UserList extends Component {
  static contextType = AbilityContext
  constructor(props) {
    super(props)
    this.state = {
      userId: props.userId,
      user: props.user,
      basicInfoModal: {basicInfoModalShow: false, basicInfoModalData: {}},
      isArtist: this.props.isArtist,
      data: {},
      sessionCampaignId: localStorage.getItem('sessionCampaignId'),
      sessionPlaylistsIds: localStorage.getItem('sessionPlaylistsIds'),
      sessionTrackId: localStorage.getItem('sessionTrackId'),
      sessionTrackName: localStorage.getItem('sessionTrackName'),
      load: true
    }
    this._editBasicInfoModal = this._editBasicInfoModal.bind(this)
    this._deleteUser = this._deleteUser.bind(this)
  }
  //************************************//
   _closeBasicInfoModal = () => {
    this.setState({basicInfoModal: {basicInfoModalShow: false, basicInfoModalData: {}}})
  }
  //************************************//
  _openBasicInfoModal = () => {
    this.setState({basicInfoModal: {basicInfoModalShow: true, basicInfoModalData: {}}})
  }
  //************************************//
  _editBasicInfoModal = (data) => {
    this.setState({basicInfoModal: {basicInfoModalShow: true, basicInfoModalData: data}})
  }
  //*************************************//
  createNewArtistCampaign = () => {
    this.props.history.push('/select/platform')
  }
  //************************************//
  _deleteUser = (id) => {
    _confirm({
      callback: (c) => {
        _deleteCampaign(id, () => {
          this.dataTableRef._refresh()
          // c()
          if (process.env.REACT_APP_AUTH_MODULE === 'user') { 
          _getCampaignsStat(
            (data) => {
              console.log(data)
                this.setState({ data })
                this.setState({ load: false })
               
            },
            (err) => {
              console.log(err)
              
            }
        )
          }
        })
      }
    })
  }
  //create campaign for artist
   createCampaign = () => {

    // setLoadingCard(true)
    //if (sessionId) {
      _stripePaymentCampaignSuccess(
      {
        checkout: this.state.sessionCampaignId
      },
      (data) => {
        this.dataTableRef._refresh()
        console.log(data)
        // setLoadingCard(false)
        if (data.checkout.success === true) {
          const dataSent = {
            playlist_id: JSON.parse(this.state.sessionPlaylistsIds),
            spotify_track_id: this.state.sessionTrackId,
            track_name: this.state.sessionTrackName,
            status: 4,  
            payment_method: 1,  
            payer_email : getUserData().email,
            payer_firstname : getUserData().name,
            payer_surname : getUserData().name,
            transaction_id : data.checkout.payment
          }
          console.log(dataSent)
          _storeArtistExpressPlaylistCampaign(
              dataSent,
              () => {
                this.dataTableRef._refresh()
              },
              (err) => {
                if (err) {
                  const arr = {}
                  for (const f in err) {
                    if (err[f] !== null) arr[f] = err[f][0]
                  }
                  setValErrors(arr)
                }
              }
            )
          localStorage.setItem('sessionCampaignId', null)
          localStorage.setItem('sessionPlaylistsIds', null)
          localStorage.setItem('sessionTrackId', null)
          localStorage.setItem('sessionTrackName', null)
        } else {
          localStorage.setItem('sessionCampaignId', null)
          localStorage.setItem('sessionPlaylistsIds', null)
          localStorage.setItem('sessionTrackId', null)
          localStorage.setItem('sessionTrackName', null)
        }
      },
      (error) => {
        localStorage.setItem('sessionCampaignId', null)
        localStorage.setItem('sessionPlaylistsIds', null)
        localStorage.setItem('sessionTrackId', null)
        localStorage.setItem('sessionTrackName', null)
        // setLoadingCard(false)
        console.log(error)
      }
    )
    // } else { 
    //   console.log('checkout is null')
    // }

  }
  componentDidMount() {  
    if (this.state.sessionCampaignId !== null && this.state.sessionCampaignId !== "null") {
      this.createCampaign()
    } else {
      console.log('empty id')
    }
    if (process.env.REACT_APP_AUTH_MODULE === 'user') {  
    _getCampaignsStat(
      (data) => {
        console.log(data)
          this.setState({ data })
          this.setState({ load: false })
         
      },
      (err) => {
        console.log(err)
        
      }
  )
  }
  }
  //************************************//
  render () {
    const {basicInfoModalShow, basicInfoModalData} = this.state.basicInfoModal
    const hasAction = _hasAnyAbility(this.context, tableActions)
    console.log('data', this.state.data)
    return (
      <Fragment>
       {process.env.REACT_APP_AUTH_MODULE === 'user' &&
        (this.state.load === false ? (
          <StatsCard stats={this.state.data?.campaigns} />
        ) : (
          <ShimmerThumbnail width={'100%'} height={202} rounded />
        ))}      
        <Breadcrumbs breadCrumbMainTitle={''} breadCrumbTitle={'Campaigns List'} breadCrumbParent='' breadCrumbActive='' >
          <CanCall action='custom.campaigns.add' id='addUserBtn'>
          {
              (process.env.REACT_APP_AUTH_MODULE === 'user' || this.props.user.user_type_id === 1) && (
                <ButtonGroup className='mb-1'>
                  {/* <Button.Ripple className='btn-icon' color='primary' onClick={this._openBasicInfoModal}> */}
                    {/* <span className='ml-25'>Create New Campaign</span> */}
                  {/* </Button.Ripple> */}
                  <Button.Ripple className='btn-icon' color='primary' onClick={process.env.REACT_APP_AUTH_MODULE === 'user' ? this._openBasicInfoModal : this.createNewArtistCampaign}>
                    {/* <FilePlus size={16} /> */}
                    <span className='ml-25'>New Campaign </span>
                  </Button.Ripple>
                 
                  </ButtonGroup>
              )
          }
          </CanCall>
        </Breadcrumbs>
        <Row>
          <Col sm='12'>
            <DataTable ref={(ref) => { this.dataTableRef = ref }} uri={this.props.link ?? 'campaigns'} columns={tableColumns(this.state, this._editBasicInfoModal, this._deleteUser, hasAction)} hasIndexing={true} hasFilter={true} defaultCollapse={true}/>
          </Col>
        </Row>
        {basicInfoModalShow && <BasicInfoWizardModal successCallback={this.dataTableRef._refresh} data={basicInfoModalData} onClose={this._closeBasicInfoModal}/>}
      </Fragment>
    )
  }
}
//************************************//
const mapStateToProps = store => ({
  loading: store.app.loading,
  userId: _.get(store, `${process.env.REACT_APP_AUTH_MODULE}.userData.id`),
  user: _.get(store, `${process.env.REACT_APP_AUTH_MODULE}.userData`)
})
export default withRouter(connect(mapStateToProps, null, null, { forwardRef: true })(UserList))
