import React from "react"
import {Briefcase, FileText, Home, Shield, Users, Settings, Music, CheckCircle} from "react-feather"

import {trans} from '@utils'
import {Message} from "@material-ui/icons"

export const Navigation = [
	{
		id: 'dashboard',
		title: "Dashboard",
		icon: <Home size={20}/>,
		navLink: '/dashboard',
		action: 'call',
		resource: 'general'
	},
    {
    	header: 'Influencer Platform',
		action: 'call',
		resource: 'general'
    },
    {
    	id: '5',
    	title: 'Users',
    	icon: <Users />,
    	navLink: '/users/list',
		action: 'call',
		resource: 'view.main.users.tab.list'
    },
    
	{
        id: 'campaigns',
        title: 'Campaigns',
        icon: <Briefcase size={20} />,
        children: [
            {
				id: '1',
				title: 'Custom Campaigns',
				icon: <Briefcase />,
				navLink: '/campaigns/list',
				action: 'call',
				resource: 'view.main.custom.campaigns.tab.list'
			},
			
			{
				id: '2',
				title: 'Express Campaigns',
				icon: <Briefcase />,
				navLink: '/express/campaigns/list',
				action: 'call',
				resource: 'view.main.express.campaigns.tab.list'
			}
            
        ]
    },
	{
		id: '2',
		title: 'Express Playlists',
		icon: <Briefcase />,
		navLink: '/express/playlists/list',
			action: 'call',
			resource: 'view.main.express.playlist.tab.list'
	},
    {
    	id: '7',
    	title: 'Invoices',
    	icon: <FileText />,
    	navLink: '/invoices/list',
			action: 'call',
			resource: 'view.main.invoices.tab.list'
    },
    {
    	id: '8',
    	title: 'Payouts',
    	icon: <FileText />,
    	navLink: '/payouts/list',
			action: 'call',
			resource: 'view.main.payouts.tab.list'
    },
	{
    	id: '20',
    	title: 'Payout Transfers',
    	icon: <FileText />,
    	navLink: '/transfers/list',
			action: 'call',
			resource: 'view.main.transfers.tab.list'
    },
    {
    	id: '9',
    	title: 'Reports',
    	icon: <FileText />,
    	navLink: '/reports',
			action: 'call',
			resource: 'view.main.reports.tab.list'
    }
]
