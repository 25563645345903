import React from "react"
import { File, Home, Codesandbox } from "react-feather"

import { navTrans } from '@utils'

export const Navigation = [
	{
		header: 'Tickets & Support Center',
		action: 'call',
		resource: 'general'
	},
	// {
	//     id: 'tickets_center',
	//     title: 'Tickets & Support Center',
	//     icon: <Codesandbox size={20} />,
	//     children: [
	{
		id: 'tickets',
		title: 'Support Tickets',
		icon: <File />,
		navLink: '/tickets/list',
		action: 'call',
		resource: 'view.main.tickets.tab.list'
	},
	{
		id: 'departments',
		title: 'Departments',
		icon: <Home />,
		navLink: '/tickets/departments',
		action: 'call',
		resource: 'view.main.departments.tab.list'
	}
	//     ]
	//   }

]