import { useState } from 'react'
import classnames from 'classnames'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import 'cleave.js/dist/addons/cleave-phone.us'
import { FormGroup, Label, Button, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import _ from "lodash"

import { trans } from '@utils'
import { ButtonSpinner, ErrorMessages } from '@src/components'

import { _getAllStatusesWithQ, _changeFormStatus } from "../../redux/actions"
import AsyncSelect from "react-select/async"

const BasicInfoModal = (props) => {

  const loading = useSelector(state => state.app.loading)
  const { errors, handleSubmit, control, trigger, getValues, setValue, register, watch } = useForm()
  const [isEditAction, setIsEditAction] = useState(props.data.id)
  const [open, setOpen] = useState(true)
  const [valErrors, setValErrors] = useState({})
  const [defaultTier, setDefaultTier] = useState(_.get(props, 'data.is_default') ?? false)
  const [formData, setFormData] = useState({
    id: _.get(props, 'data.id', null),
    track_url: _.get(props, 'data.track_url', ''),
    type: _.get(props, 'data.type', ''),
    genre_id: _.get(props, 'data.genre_id.id', ''),
    // updated_by: _.get(props, 'data.updated_by', ''),
    form_type_id: _.get(props, 'data.form_type_id') ? _.get(props, 'data.form_type_id.id') : '',
    assigned_to: _.get(props, 'data.assigned_to') ? _.get(props, 'data.assigned_to.id') : '',
    // created_by: _.get(props, 'data.created_by') ? {label: _.get(props, 'data.created_by.name'), value: _.get(props, 'data.created_by.id')} : '',
    status_id: _.get(props, 'data.status_id', ''),
    track_file: _.get(props, 'data.track_file', ''),
    cover_image: _.get(props, 'data.cover_image', ''),
    track_mood: _.get(props, 'data.track_mood', ''),
    track_bio: _.get(props, 'data.track_bio', ''),
    promotion: _.get(props, 'data.promotion', ''),
    release_date: _.get(props, 'data.release_date', ''),
    previous_achievements: _.get(props, 'data.previous_achievements', ''),
    reference: _.get(props, 'data.reference', ''),
    distributor: _.get(props, 'data.distributor', ''),
    upc_code: _.get(props, 'data.upc_code', ''),
    label: _.get(props, 'data.label', ''),
    isrc_code: _.get(props, 'data.isrc_code', ''),
    apple_track_id: _.get(props, 'data.apple_track_id', ''),
    extra_info: _.get(props, 'data.extra_info', ''),
    facebook_link: _.get(props, 'data.facebook_link', ''),
    instagram_link: _.get(props, 'data.instagram_link', ''),
    twitter_link: _.get(props, 'data.twitter_link', ''),
    tiktok_link: _.get(props, 'data.tiktok_link', ''),
    location_timezone: _.get(props, 'data.location_timezone', ''),
    soundcloud_link: _.get(props, 'data.soundcloud_link', ''),
    phone_number: (props.data.phone_number ?? '')

  })
  const _close = () => {
    setOpen(false)
    props.onClose()
  }

  const onSubmit = (data) => {
    trigger()
    if (_.isEmpty(errors)) {
      if (formData.id) {
        _changeFormStatus(formData.id, data.status_id.value,
          () => {
            props.successCallback()
            _close()
          },
          (err) => {
          }
        )
      }
    }
  }

  return (
    <Modal
      isOpen={open}
      toggle={_close}
      unmountOnClose={true}
      backdrop={true}
      className='sidebar-lg'
      contentClassName='p-0'
      modalClassName='modal-slide-in sidebar-todo-modal'
    >
      <Form action='/' className='flex-grow-1 d-flex flex-column' onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={_close} className='mb-1'>
          {isEditAction ? 'Edit Form' : 'Add Form'}
        </ModalHeader>
        <ModalBody className='flex-grow-1 pb-sm-0 pb-3 modal-body'>

          <FormGroup>
            <Label className='form-label' for='status_id'>
              Status
            </Label>
            <Controller
              as={AsyncSelect}
              control={control}
              name={'status_id'}
              id='status_id'
              isClearable={false}
              isMulti={false}
              classNamePrefix='select'
              defaultOptions
              cacheOptions
              loadOptions={_getAllStatusesWithQ}
              className={classnames({ 'is-invalid': errors['name'] || _.get(props.valErrors, 'name') })}
              defaultValue={formData?.status_id ? { label: formData?.status_id.name, value: formData?.status_id.id } : ''}
              rules={{
                required: true
              }}
            />
            <ErrorMessages valErrors={props.valErrors} errors={errors} name={'status_id'} />
          </FormGroup>

        </ModalBody>
        <ModalFooter className='justify-content-center'>
          <Button.Ripple type='submit' className='flex-grow-1' color='primary' disabled={loading}>
            {loading ? <ButtonSpinner /> : null}
            <span>{trans('gen.actions.save')}</span>
          </Button.Ripple>
          <Button.Ripple type='button' className='flex-grow-1' color='secondary' disabled={loading} onClick={_close}>
            <span>{trans('gen.actions.cancel')}</span>
          </Button.Ripple>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default BasicInfoModal

